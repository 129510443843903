.loader-page {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 10000;
}
.title-section {
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 20px;
}
.table-action-btn {
  margin-left: 10px;
  font-size: 16px;
}
.table-top-btn {
  float: right;
  padding-bottom: 20px;
  margin-top: -50px;
}
.table-wrapper {
  background-color: #ffffff;
  width: 100%;
  overflow-x: scroll;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}
.label-error {
  padding: 10px 0px;
}
.form-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px;
}
.label-color {
  color: var(--theme-color);
  font-weight: bold;
}
.label-light {
  color: $gray;
  font-weight: 300;
}
.label-red {
  color: $red;
}
.account-logo, .account-iso {
  max-height: 45px;
  max-width: 120px;
  img {
    max-height: 100%;
    max-width: 100%;
  }
}
.empty-list {
  text-align: center;
  font-weight: 300;
  font-size: 16px;
  span {
    color: $gray;
    width: 100%;
    display: block;
  }
}

@media (max-width: 767px) {
 
  .title-section {
    font-size: 20px;
    font-weight: 600;
    padding: 20px 0px;
  }
}