[class^="ant-"] {
  font-family: $font-default;
  font-size: 16px !important;
}

.ant-layout-header {
  background-color: $white;
  color: $black;
  padding: 0px 20px;
  border-bottom: 2px solid var(--theme-color);
}
.ant-dropdown-menu {
  a {
    font-size: 17px !important;
    .anticon {
      margin-right: 5px;
    }
  }
}
.ant-layout {
  background-color: $white;
  .ant-layout-sider {
    background-color: $white;
    height: calc(100dvh - 64px);
    border-inline-end: 1px solid rgba(5, 5, 5, 0.06);
    border-inline-start: 1px solid rgba(5, 5, 5, 0.06);
  }
  .ant-layout-content {
    background-color: #f4f7fe;
    padding: 20px;
  }
  .ant-layout-footer {
    background-color: $white;
    padding: 20px;
  }
  .ant-menu {
    background-color: transparent;
    border: none !important;
    font-size: 18px !important;
    font-weight: 600;
    margin-top: 10px;
  }
  .ant-menu-item-selected {
    background-color: var(--theme-color);
    color: $white;
  }
  .ant-layout-footer {
    border-top: 1px solid rgba(5, 5, 5, 0.06);
  }
  .ant-form-item {
    margin-bottom: 10px;
  }
}
.ant-drawer .ant-menu {
  border: none !important;
}
.ant-table .ant-badge {
  width: 140px;
}
